<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
      :lastPage="nLastPage"
      @pageChange="fetchEquipe(filters, $event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Equipes</v-toolbar-title>
          <v-spacer></v-spacer>

          <RegisterBottom
            v-can-access="647"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <FilterEquipe @selectedFilters="fetchEquipe((filters = $event))" />
        </v-toolbar>
      </template>
      <template v-slot:[`item.nome_equipe`]="{ item }">
        {{ item.nome_equipe | TitleCase }}
      </template>
      <template v-slot:[`item.id_band`]="{ item }">
        <v-icon :color="bandeiras[item.id_band].color" dark>
          {{ bandeiras[item.id_band].icon }}
        </v-icon>
      </template>
      <template v-slot:[`item.secoes`]="{ item }">
        <v-chip v-if="item.secoes != null" color="success" dark> Sim </v-chip>
        <v-chip v-else color="error" dark> Não </v-chip>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip
          :color="statusAtivo[item.ativo].color"
          dark
          @click="statusItem(item)"
        >
          {{ statusAtivo[item.ativo].text }}
        </v-chip>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn icon @click="editItem(item)" color="orange" dark>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>-->
        <!--  <v-btn
          icon
          @click="vinculaSecao(item)"
          class="ml-2"
          color="primary"
          dark
        >
          <v-icon>mdi-swap-horizontal</v-icon>
        </v-btn>-->
        <!--  <v-btn
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->
        <IconBottom :name="'edit'" @click="editItem(item)" />
        <IconBottom :name="'bond'" @click="vinculaSecao(item)" />
        <IconBottom :name="'delete'" @click="deleteItem(item)" />
        <v-btn @click="getData(item.id_equipe)" icon color="primary"
          ><v-icon>mdi-account-search</v-icon>
        </v-btn>
      </template>
    </BaseTable>
    <ModalCadEquipe
      :dialog="dialog"
      :labelBtn="labelBtn"
      :editEquipe="equipe"
      @close="refresh()"
    />
    <DraggableEquipeSecao
      :dialog="dialogDraggable"
      :dadosEquipe="dados"
      :secoesNaoVinculadas="secoesNaoVinculadas"
      :secoesVinculadas="equipeSecao"
      @close="refresh()"
      @change:fecthVinculaSecao="vinculaSecao($event)"
    />
    <ModalListas
      :item="team_id"
      :dialogColab="visible"
      @closeColab="visible = false"
    />
  </div>
</template>

<script>
import IconBottom from "@/components/shared/bottons/IconBottom";
import comissao from "@/services/http/comissaoService";
import BaseTable from "@/components/shared/NewBaseTable";
import ModalCadEquipe from "./ModalCadEquipe";
import DraggableEquipeSecao from "./DraggableEquipeSecao";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import FilterEquipe from "./FilterEquipe";
import statusAtivo from "@/mixins/statusAtivo";
import banderiras from "@/mixins/bandeiras";
import ModalListas from "@/components/shared/ModalColaboradoresEquipe.vue";
export default {
  name: "TabelaEquipe",

  components: {
    BaseTable,
    ModalCadEquipe,
    DraggableEquipeSecao,
    RegisterBottom,
    FilterEquipe,
    ModalListas,
    IconBottom
  },

  mixins: [statusAtivo, banderiras],

  data() {
    return {
      loading: false,
      dialog: false,
      dialogDraggable: false,
      secoesNaoVinculadas: [],
      equipeSecao: [],
      dados: {},
      search: "",
      labelBtn: "Salvar",
      filters: "",
      nLastPage: 0,
      headers: [
        { text: "Segmento", value: "id_band", align: "center" },
        { text: "Empresa/Equipe", value: "nome_equipe" },
        {
          text: "Nº Colaboradores",
          value: "total_colaboradores",
          align: "center"
        },
        { text: "Possui Seção", value: "secoes", align: "center" },
        { text: "Status", value: "ativo", align: "center" },
        { text: "Ações", value: "acoes", align: "center" }
      ],
      info: [],
      equipe: {},
      visible: false,
      team_id: ""
    };
  },

  methods: {
    async fetchEquipe(filter, nPage = 1) {
      this.loading = true;
      let { data } = await comissao()
        .tipoEquipe()
        .show({
          page: nPage,
          ativo: "S",
          ...filter
        });
      this.nLastPage = data.last_page;
      this.loading = false;
      this.info = data.data;
    },
    getData(team_id) {
      this.team_id = team_id;
      this.visible = true;
    },
    editItem(item) {
      this.equipe = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    refresh() {
      this.dialogDraggable = false;
      this.fetchEquipe(this.filters);
      this.dialog = false;
    },

    async vinculaSecao(item) {
      this.dados = {
        id_equipe: item.id_equipe,
        id_empresa: item.id_empresa,
        nome_equipe: item.nome_equipe
      };
      let secoes = await this.showSecao(item);
      this.equipeSecao = await this.showEquipeSecao(item);

      let secoesVinculadas = this.equipeSecao.map(value => value.cod_secao);

      this.secoesNaoVinculadas = secoes.filter(
        value => !secoesVinculadas.includes(value.cod_secao)
      );

      this.dialogDraggable = true;
    },

    async showSecao(item) {
      let { data } = await comissao()
        .secao()
        .show({
          per_page: -1,
          id_empresa: item.id_empresa
        });
      return data.data;
    },

    async showEquipeSecao(item) {
      let { data } = await comissao()
        .equipeSecao()
        .show({
          per_page: -1,
          id_equipe: item.id_equipe
        });
      return data.data;
    },

    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          `Deseja deletar a equipe ${item.nome_equipe}?`,
          "Essa ação não pode ser desfeita"
        );
        comissao()
          .tipoEquipe(item.id_equipe)
          .delete(
            {},
            { notification: true, message: "Equipe deletada com sucesso!" }
          );
      } catch (error) {
        console.log(error);
      } finally {
        this.fetchEquipe(this.filters);
      }
    }
  },

  mounted() {
    this.fetchEquipe();
  }
};
</script>

<style></style>
