<template>
  <v-dialog v-model="dialog" persistent max-width="70%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{ labelBtn != "Salvar" ? "Editar Equie" : "Cadastrar Equipe" }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" md="6" lg="6">
              <BaseSelect
                prepend-inner-icon="mdi-file-table-box-multiple-outline"
                clearable
                single-line
                label="Segmento"
                item-text="descricao"
                item-value="id_band"
                service="sistemaService.tipoBandeiras"
                :disabled="locked"
                :filters="{ id_band: '2,4' }"
                v-model="form.id_band"
                :isCompany="true"
              />
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <BaseSelect
                label="Empresa"
                v-model="form.id_empresa"
                service="sistemaService.empresa"
                item-text="apelido"
                item-value="id_empresa"
                prepend-inner-icon="mdi-domain"
                :rules="[rules.required]"
                clearable
                :isCompany="true"
                :multiple="false"
                :disabled="locked"
                :filters="{
                  id_band: form.id_band,
                  calcula_comissao: 'S'
                }"
              />
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-text-field
                label="Nome Equipe"
                :rules="[rules.required]"
                v-model="form.nome_equipe"
                chips
              >
              </v-text-field>
            </v-col>
            <v-col class="pt-1" cols="12" md="6" lg="6">
              <v-autocomplete
                label="Status"
                v-model="form.ativo"
                chips
                :items="[
                  { text: 'Ativo', value: 'S' },
                  { text: 'Inativo', value: 'N' }
                ]"
                :rules="[rules.required]"
                :multiple="false"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? put() : send()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import comissao from "@/services/http/comissaoService";
import BaseSelect from "@/components/shared/BaseSelectService";

export default {
  name: "ModalCadEquipe",

  mixins: [rules],

  components: {
    BaseSelect
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    },
    editEquipe: {
      type: Object
    }
  },

  data() {
    return {
      form: {},
      filters: {},
      locked: false
    };
  },

  watch: {
    editEquipe(value) {
      this.form = value;
    },
    labelBtn(value) {
      this.locked = value == "Editar" ? true : false;
    }
  },

  methods: {
    async send() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await comissao()
          .tipoEquipe()
          .store(this.form, {
            notification: true,
            message: "Equipe cadastrada com sucesso!"
          });
      }
      this.close();
    },

    async put() {
      const validateStatus = this.$refs.form.validate();

      if (validateStatus) {
        await comissao()
          .tipoEquipe(this.form.id_equipe)
          .update(this.form, {
            notification: true,
            message: "Equipe editada com sucesso!"
          });
      }
      this.close();
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
