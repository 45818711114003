var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.info,"loading":_vm.loading,"paginate":true,"lastPage":_vm.nLastPage},on:{"pageChange":function($event){return _vm.fetchEquipe(_vm.filters, $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Equipes")]),_c('v-spacer'),_c('RegisterBottom',{directives:[{name:"can-access",rawName:"v-can-access",value:(647),expression:"647"}],on:{"click":function($event){(_vm.dialog = true), (_vm.labelBtn = 'Salvar')}}}),_c('FilterEquipe',{on:{"selectedFilters":function($event){_vm.fetchEquipe((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.nome_equipe",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.nome_equipe))+" ")]}},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.bandeiras[item.id_band].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.bandeiras[item.id_band].icon)+" ")])]}},{key:"item.secoes",fn:function(ref){
var item = ref.item;
return [(item.secoes != null)?_c('v-chip',{attrs:{"color":"success","dark":""}},[_vm._v(" Sim ")]):_c('v-chip',{attrs:{"color":"error","dark":""}},[_vm._v(" Não ")])]}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.statusAtivo[item.ativo].color,"dark":""},on:{"click":function($event){return _vm.statusItem(item)}}},[_vm._v(" "+_vm._s(_vm.statusAtivo[item.ativo].text)+" ")])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('IconBottom',{attrs:{"name":'edit'},on:{"click":function($event){return _vm.editItem(item)}}}),_c('IconBottom',{attrs:{"name":'bond'},on:{"click":function($event){return _vm.vinculaSecao(item)}}}),_c('IconBottom',{attrs:{"name":'delete'},on:{"click":function($event){return _vm.deleteItem(item)}}}),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.getData(item.id_equipe)}}},[_c('v-icon',[_vm._v("mdi-account-search")])],1)]}}],null,true)}),_c('ModalCadEquipe',{attrs:{"dialog":_vm.dialog,"labelBtn":_vm.labelBtn,"editEquipe":_vm.equipe},on:{"close":function($event){return _vm.refresh()}}}),_c('DraggableEquipeSecao',{attrs:{"dialog":_vm.dialogDraggable,"dadosEquipe":_vm.dados,"secoesNaoVinculadas":_vm.secoesNaoVinculadas,"secoesVinculadas":_vm.equipeSecao},on:{"close":function($event){return _vm.refresh()},"change:fecthVinculaSecao":function($event){return _vm.vinculaSecao($event)}}}),_c('ModalListas',{attrs:{"item":_vm.team_id,"dialogColab":_vm.visible},on:{"closeColab":function($event){_vm.visible = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }